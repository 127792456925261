import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../api/store";

//  要添加路由，则在routes中添加一个对象  by hailin
const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Contact.vue"),
  },
  {
    path: "/resource",
    name: "Resource",
    component: () => import("../views/Resource.vue"),
  },
  {
    path: "/database",
    name: "DataBase",
    component: () => import("../views/DataBase.vue"),
  },
  {
    path: "/service",
    name: "Service",
    component: () => import("../views/Service.vue"),
  },
  {
    path: "/service/activity-prediction/single",
    name: "SinglePrediction",
    component: () => import("../views/SinglePrediction.vue"),
  },
  {
    path: "/service/activity-prediction/batch",
    name: "BatchPrediction",
    component: () => import("../views/BatchPrediction.vue"),
  },
  {
    path: "/service/activity-prediction/single-predict-result/:email/:resultId",
    name: "SinglePredictResult",
    props: true,
    component: () => import("../views/SinglePredictResult.vue"),
  },
  {
    path: "/service/virtual/virtual-result/:email/:resultId",
    name: "VirtualResult",
    props: true,
    component: () => import("../views/VirtualResult.vue"),
  },
  {
    path: "/service/similarity/similarity-result/:email/:resultId",
    name: "SimilarityResult",
    props: true,
    component: () => import("../views/SimilarityResult.vue"),
  },
  {
    path: "/service/virtual",
    name: "Virtual",
    component: () => import("../views/Virtual.vue"),
  },
  {
    path: "/service/MolecularGeneration",
    name: "MolecularGeneration",
    props: true,
    component: () => import("../views/MolecularGeneration.vue"),
  },

  {
    path: "/service/activity-prediction/multi-predict-result/:email/:resultId",
    name: "MultiPredictResult",
    props: true,
    component: () => import("../views/MultiPredictResult.vue"),
  },
  {
    path: "/service/predict-result-detail",
    name: "PredictResultDetail",
    component: () => import("../views/PredictResultDetail.vue"),
  },
  {
    path: "/publication",
    name: "Publication",
    component: () => import("../views/Publication.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
  },
  {
    path: "/tutorial",
    name: "Tutorial",
    component: () => import("../views/Tutorial.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   if (to.meta.requireAuth) {
//     if (store.state.token) {
//       next();
//     } else {
//       next({
//         path: '/account/sign-in',
//         query: { redirect: to.fullPath },
//       })
//     }
//   } else {
//     next();
//   }
// })

export default router;

<template>
  <div class="header" :style="headerStyle">
    <div id="header-logo">
      <img id="logo" src="../assets/logos/logo.png" alt="logo" @click="routeTo('/home')" />
    </div>
    <ul class="menu">
      <li class="menu-item" @click="routeTo('/search')">
        <div id="search-icon">
          <el-icon>
            <search />
          </el-icon>
        </div>
      </li>
      <li class="menu-item" @click="routeTo('/home')">Home</li>
      <li class="menu-item menu-sub-item">
        Service&nbsp; &nbsp;
        <el-icon class="el-icon">
          <arrow-down />
        </el-icon>
        <div class="menu-popper">
          <div class="sub-item" @click.stop="routeTo('/service/activity-prediction/single')">
            Activity Predicting
          </div>
          <div class="sub-item" @click.stop="routeTo('/service/virtual')">
            Virtual Screening
          </div>
          <div class="sub-item" @click.stop="routeTo('/service/MolecularGeneration')">
            Similarity Search
          </div>
        </div>
      </li>
      <li class="menu-item" @click="routeTo('/resource')">Resource</li>
      <!-- <li class="menu-item" @click="routeTo('/database')">Database</li> -->
      <li class="menu-item" @click="routeTo('/tutorial')">Tutorial</li>
      <li class="menu-item" @click="routeTo('/contact')">Contact</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      scrollTop: 0,
      headerBg: "linear-gradient(var(--color1-dark), var(--color1-light));",
    };
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
  computed: {
    headerStyle() {
      if (this.$route.path == "/home") {
        return {
          position: "fixed",
          background:
            "linear-gradient(var(--color1-dark), var(--color1-light))",
        };
      } else {
        return {
          position: "absolute",
          background: "transparent",
        };
      }
    },
  },
};
</script>

<style scoped>
.header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  display: flex;
  padding: 0px 20px;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  color: white;
  background: linear-gradient(var(--color1-dark), var(--color1-light));
}

#header-logo {
  /* flex: 1; */
  height: 50%;
  /* padding: 0 10px; */
  cursor: pointer;
  text-align: start;
}

#logo {
  height: 100%;
}

.menu {
  /* flex: 1; */
  height: 100%;
  justify-content: center;
  list-style: none;
  display: flex;
  align-items: center;
  /* background: red; */
}

.menu-item {
  font-size: var(--menu-size);
  margin-left: 20px;
  cursor: pointer;
  height: 100%;
  line-height: 60px;
  padding: 0 10px;
  position: relative;
}

#search-icon {
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  /* text-align: center; 
  /* padding: 0 20px; */
  /* width: 50px; */
}

#search-icon .el-icon {
  position: static;
  transform: translateY(0);
}

.menu-item .el-icon,
.user .el-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -10px;
  font-size: 20px;
  transition: 300ms ease;
}

.menu-item:hover {
  background: var(--color1-light);
}

.sub-item:hover {
  background: var(--color2-dark);
}

.menu-sub-item:nth-child(3):hover .el-icon,
.user:hover .el-icon {
  transform: translateY(-50%) rotate(180deg);
}

.menu-sub-item:nth-child(3):hover .menu-popper,
.user:hover .user-popper {
  display: block;
}

.menu-popper {
  transition: 100ms ease;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  background-color: var(--color2-light);
  box-shadow: 0 2px 2px var(--color2-dark);
  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  display: none;
  line-height: 40px;
}

.user-popper {
  transition: 100ms ease;
  position: absolute;
  top: 100%;
  right: 0;
  color: black;
  background-color: var(--color2-light);
  box-shadow: 0 2px 2px var(--color2-dark);
  padding: 10px;
  border-radius: 10px;
  z-index: 999;
  display: none;
  line-height: 40px;
}

.sub-item {
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 5px;
  padding: 0 5px;
  font-size: 16px;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.5); */
}

.login {
  /* background: blue; */
  height: 40px;
  display: flex;
  flex: 1;
  justify-content: end;
  flex-flow: row nowrap;
}

.btn {
  padding: 0 5px;
  height: 100%;
  margin: 0 5px;
  font-size: 16px;
  border-radius: 8px;
  border: none;
  background: none;
  color: inherit;
  cursor: pointer;
  transition: 300ms ease;
}

.btn:hover {
  background: rgba(255, 255, 255, 0.3);
  transform: scale(1.1);
}

.btn.sign-up {
  border: 1px solid rgb(255, 255, 255, 0.8);
}

.user {
  flex: 1;
  display: flex;
  height: 100%;
  flex-flow: row nowrap;
  justify-content: end;
  align-items: center;
  position: relative;
  padding-right: 20px;
}

.avatar {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  /* padding: 8px; */
  margin: 0 10px;
  border-radius: 100%;
  border-radius: 2px solid rgba(0, 0, 0, 0.3);
}

.avatar img {
  border-radius: 100%;

  width: 40px;
  height: 40px;
}

.username {
  margin: 0 10px;
  font-size: 18px;
}
</style>

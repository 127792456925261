<template>
  <div class="loading">
    <div class="content">
      <!-- <p>Loading.....</p> -->
      <div class="loadingOne">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
};
</script>

<style scoped>
.loading {
  position: absolute;
  /* height: 100vh; */
  /* width: 100vw; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.4);
}

.content {
  width: 360px;
  height: 360px;
  /* background-color: var(--color2-light); */

  border-radius: 20px;
  /* color: blue; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 26px;
}

.title {
  width: 100%;
  text-align: center;
  margin: 60px 0;
  font-size: 18px;
  color: #999;
}
.loadingOne {
  width: 80px;
  height: 40px;
  margin: 0 auto;
}
.loadingOne span {
  display: inline-block;
  width: 10px;
  height: 100%;
  margin: 0 1px;
  border-radius: 4px;
  background: var(--color2-light);
  -webkit-animation: load 1.2s ease infinite;
  animation: load 1.2s ease infinite;
}
@-webkit-keyframes load {
  0%,
  100% {
    height: 50px;
    background: var(--color2-light);
  }
  50% {
    height: 80px;
    margin: -15px 0;
    background: var(--color2-dark);
  }
}
.loadingOne span:nth-child(2) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.loadingOne span:nth-child(3) {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.loadingOne span:nth-child(4) {
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
}
.loadingOne span:nth-child(5) {
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}
</style>

<template>
  <div class="home">
    <div class="introduction">
      <div class="text">
        <h2 class="title">What's DeepCancerMap</h2>
        <img src="../assets/homepage/decoration1.png" />
        <p class="main">
          Target-based drug discovery (TDD) and phenotype-based drug discovery
          (PDD) are two mainstream approaches in anticancer drug discovery. TDD
          has several advantages including simplicity, lower cost, and easy to
          achieve efficient structure-activity relationship (SAR) for lead
          optimization. However, the identification and validation of druggable
          targets is difficult, and if a selected target is undruggable, it may
          lead practitioners to pursue projects and compounds that fail to
          translate into clinical results. PDD (e.g., whole-cell activity), an
          original but indispensable drug design and screening method, has
          gained attention in recent years because of the number of discovered
          and approved drugs. <br />DeepCancerMap is a data-driven anticancer drug
          discovery platform that integrates a total of 832 highly accurate deep
          learning-based computational TDD and PDD models. The platform provides
          three modules, including cancer cell- and target-based activity
          prediction and selectivity prediction, virtual screening and molecular
          similarity search, to facilitate the discovery and design of new
          anticancer drugs.
        </p>
      </div>
    </div>
    <!-- <div class="rowcenter">
      <div style="width: 1082px">
        <h2 class="subtitle">Deep Learning Algorithm</h2>
        <div class="rowcenter">
          <img style="width: 270pt; text-align: center; margin-top: 5px" src="../assets/homepage/decoration2.png" />
        </div>
        <p class="algorithmintro">
          We advanced a novel deep learning algorithm named FP-GNN which study
          and integrate the information from molecular graph and molecular
          fingerprint, especially suitable for the fluctuant data challenges.
          Comparison performance test with many sophisticated machine learning
          algorithms on 13 classical benchmark public datasets and an unbiased
          dataset LIT-PCBA indicates FP-GNN can achieve state-of-the-art
          performance.
        </p>
        <img style="margin-top: 42px; width: 100%" src="../assets/homepage/photo1.png" />
      </div>
    </div> -->
    <div class="rowcenter">
      <div style="width: 1082px">
        <h2 class="subtitle">Functions</h2>
        <div class="rowcenter">
          <img style="width: 270pt; text-align: center; margin-top: 5.5px" src="../assets/homepage/decoration2.png" />
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo1.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Activity Predicting</h2>
            <p class="functionintro">
              The cancer cell- and anticancer target-based activity prediction
              and selectivity prediction module consists of a series of
              anticancer molecules activity prediction models that are
              constructed based on the state-of-the-art FP-GNN deep learning
              algorithm. It enables accurate and rapid prediction of the
              inhibitory activity and selectivity of a given molecule against
              406 cancer cell lines or 426 anticancer targets, thereby helping
              users predict its potential anticancer activity.
            </p>
            <p class="jump-to">
              <span @click.stop="routeTo('/service/activity-prediction/single')">Jump To >></span>
            </p>
          </div>
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo2.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Virtual Screening</h2>
            <p class="functionintro">
              Virtual screening is an effective drug discovery method. The
              virtual screening module integrates established cell- and
              target-based prediction models. Users can select targets or cancer
              cell lines of interest to discover new anticancer small molecules.
              Of course, users can download the model of interest (which has
              been compiled into a local version of the executable program) for
              further large-scale virtual screening.
            </p>
            <p class="jump-to">
              <span @click.stop="routeTo('/service/virtual')">Jump To >></span>
            </p>
          </div>
        </div>
        <div class="functionintros">
          <div class="functionlogo">
            <img src="../assets/homepage/logo3.png" style="width: 80%" />
          </div>
          <div class="functiontext">
            <h2 class="functiontitle">Similarity Search</h2>
            <p class="functionintro">
              Similar compounds may have similar activities or act on the same
              target. By calculating the Tanimoto coefficient based on molecular
              fingerprints, users can use the similarity search module to search
              for compounds with structural similar to user-submitted molecules
              in the built-in target- and cell-based molecular databases. This
              module enables users to quickly find similar molecules to
              speculate their cancer cell activity or fish for targets.
            </p>
            <p class="jump-to">
              <span @click.stop="routeTo('/service/MolecularGeneration')">Jump To >></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Carousel from "../components/carousel.vue";

export default {
  name: "Home",
  data() {
    return {};
  },
  components: {
    // Carousel,
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.home {
  text-align: center;
  padding-bottom: 50px;
  font-family: FZYouHJW;
}

.introduction {
  display: flex;
  flex-direction: row;
  height: 680px;
  justify-content: center;
  background-image: url("../assets/homepage/background.png");
  background-size: cover;
}

.rowcenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.page-bottom-intro {
  height: 100%;
  font-weight: 500;
  text-align: left;
}

.page-bottom-title {
  font-size: 20pt;
  font-weight: 900;
  color: white;
}

.email {
  font-size: 14pt;
  color: white;
  margin-top: 21px;
}

.page-bottom-font {
  font-size: 14pt;
  color: white;
  line-height: 17.4pt;
}

.nonewline {
  white-space: nowrap;
}

.text {
  width: 1082px;
  text-align: left;
  margin-top: 212px;
}

.text img {
  width: 240pt;
  margin: 0px -25pt;
  margin-top: -30px;
}

.title {
  font-size: 30pt;
  font-weight: bold;
  color: white;
}

.columnstart {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.subtitle {
  font-size: 25pt;
  margin-top: 42px;
}

.functionlogo {
  height: 100%;
  margin-left: 5%;
  width: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-bottom {
  background: var(--color2-light);
  margin-top: 146px;
  height: 320px;
  width: 100%;
}

.map {
  height: 100%;
}

.main {
  width: 65%;
  font-size: 14pt;
  font-weight: lighter;
  color: white;
  margin: 41px 0px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  word-break: break-word;
}

.algorithmintro {
  width: 100%;
  font-size: 14pt;
  margin-top: 42px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  /* text-align: left; */
  word-break: break-word;
}

.functionintros {
  width: 100%;
  margin-top: 50px;
  height: 304.5px;
  background: linear-gradient(to left, var(--color2-light), var(--color2-dark));
  display: flex;
  border-radius: 10px;
  flex-direction: row;
}

.functionintro {
  width: 95%;
  font-size: 14pt;
  margin-top: 15.6px;
  line-height: 17.4pt;
  letter-spacing: 0px;
  text-align: justify;
  word-break: break-word;
}

.functiontext {
  width: 75%;
  margin-left: 5%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.columncenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.functiontitle {
  font-size: 20pt;
}

.jump-to {
  text-align: end;
  margin: 0 30px;
  font-size: 20px;
}

.jump-to span:hover {
  cursor: pointer;
  color: var(--color1-light);
}
</style>

import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElIcons from '@element-plus/icons'
import 'element-plus/dist/index.css'
import './assets/fonts/main.css'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import router from './router'
import store from '../src/api/store';

const app = createApp(App);
app.use(router);
// app.use(ElementPlus, { locale });
app.use(ElementPlus);
app.use(store);
Object.keys(ElIcons).forEach(key => {
    app.component(key, ElIcons[key]);
})
app.mount('#app');


import { createStore } from "vuex";

// const offlineBaseUrl = 'http://localhost:20193';
// const onlineBaseUrl = 'https://iShiner.idruglab.cn';
// const onlineBaseUrl = 'http://47.112.137.190:20193 http://1.12.248.177:20193'
// const onlineBaseUrl = "https://deepcancer.idruglab.cn";
// const databaseUrl = "http://1.12.248.177:9200";
const onlineBaseUrl = "https://deepcancermap.idruglab.cn";
const databaseUrl = "https://1.12.248.177:9200";
const store = createStore({
    state: {
        baseUrl: onlineBaseUrl + '/api',
        downloadUrl: onlineBaseUrl,
        databaseUrl: databaseUrl,
        // isLogin: '0',
        // token: localStorage.getItem('token') || null,
        // userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null,
        msgState: {
            isShowMsg: false,
            msgValue: "",
            msgType: 'error',
        },
        loadingState: {
            // isLoading: false,
        }
    },
    getters: {
        // getToken(state) {
        //     return state.token ? state.token : null;
        // },
        // getInfo(state) {
        //     return state.userInfo ? state.userInfo : null;
        // },
        // getAvatar(state) {
        //     return state.userInfo.avatarUrl ? state.userInfo.avatarUrl : null;
        // },
        // getServicePredictTimes(state) {
        //     return state.userInfo ? state.userInfo.servicePredictTimes : 0;
        // },
        // getServiceSelectionTimes(state) {
        //     return state.userInfo ? state.userInfo.serviceSelectionTimes : 0;
        // },
        // getServiceGenerateTimes(state) {
        //     return state.userInfo ? state.userInfo.serviceGenerateTimes : 0;
        // }
    },
    mutations: {
        // setToken(state, value) {
        //     state.token = value;
        //     localStorage.setItem('token', value);
        // },
        // removeToken(state) {
        //     state.token = null;
        //     localStorage.removeItem('token');
        // },
        // // 存储用户信息
        // setInfo(state, value) {
        //     state.userInfo = value;
        //     state.userInfo.avatarUrl = state.baseUrl + state.userInfo.avatarUrl;
        //     localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
        // },
        // // 删除用户信息
        // removeInfo(state) {
        //     state.userInfo = null;
        //     localStorage.removeItem('userInfo');
        // },
        // // 更新本地头像信息
        // setAvatar(state, value) {
        //     state.userInfo.avatarUrl = state.baseUrl + value;
        //     localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
        // },
        // 弹出提示消息
        alertMsg(state, params) {
            state.msgState.msgValue = params.msg ? params.msg : 'msg';
            state.msgState.msgType = params.type ? params.type : 'error';
            state.msgState.isShowMsg = true;
            setTimeout(() => {
                state.msgState.isShowMsg = false;
            }, params.time ? params.time : 3000);
        },
        // startLoading
        startLoading(state) {
            state.loadingState.isLoading = true;
        },
        endLoading(state) {
            state.loadingState.isLoading = false;
        }

    }
});

export default store;
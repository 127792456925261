.<template>
  <div class="footer" :style="backgroundStyle">
    <div class="footer-content">
      <div class="intro">
        <h2 class="title">About</h2>
        <p>
          DeepCancerMap is a data-driven anticancer drug discovery platform that
          integrates a total of 832 highly accurate deep learning-based
          computational TDD and PDD models. The platform provides three modules,
          including phenotypic and target-based activity prediction and
          selectivity prediction, virtual screening and molecular similarity
          search, to facilitate the discovery and design of new anticancer
          drugs.
        </p>
        <a class="copy-right">Copyright © iDruglab.&nbsp;All rights reserved</a>
      </div>
      <div class="links">
        <h2 class="title">Quick Links</h2>
        <p
          class="link-item"
          @click="routeTo('/service/activity-prediction/single')"
        >
          Activity Predicting
        </p>
        <p class="link-item" @click="routeTo('/service/virtual')">
          Virtual Screen
        </p>
        <p class="link-item">Similarity Search</p>
      </div>
      <div class="map">
        <a href="https://www.revolvermaps.com/livestats/50u0eazzc8b/">
          <img
            src="//rf.revolvermaps.com/h/m/a/0/ff0000/146/10/50u0eazzc8b.png"
            width="285"
            height="147"
            alt="Map"
            style="border: 0"
          />
        </a>
        <p class="email">Email:&nbsp;&nbsp;jasonwu717@foxmail.com</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {
    routeTo(path) {
      this.$router.push(path);
    },
  },
  computed: {
    backgroundStyle() {
      if (this.$route.path == "/home") {
        return {
          background:
            "linear-gradient(var(--color1-light), var(--color1-dark))",
        };
      } else {
        return {};
      }
    },
  },
};
</script>

<style scoped>
.footer {
  /* background-image: linear-gradient(
    to bottom,
    var(--color1-light),
    var(--color1-dark)
  ); */
  padding: 20px;
  cursor: default;
  display: flex;
  justify-content: center;
}

.footer-content {
  max-width: 80%;
  display: flex;
  color: white;
  text-align: center;
}

.intro {
  flex: 2;
  text-align: justify;
}

.links {
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.link-item {
  margin: 5px 0;
}

.link-item:hover {
  color: var(--color2-dark);
}

.map {
  /* flex: 1; */
  /* background: blue; */
}

.title {
  margin: 10px 0;
  font-size: 20pt;
}

.copy-right {
  display: block;
  margin: 5px 0;
}

.email {
  /* background: red; */
  text-align: end;
  margin-top: 2px;
}
</style>